import React, { useState } from "react"
import { Link } from "gatsby"
import Banner from "../components/banner/banner"
import Footer from "../components/footer/footer"
import Testimonial from "../components/testimonial/testimonial"
import Nav from "../components/navigation/navigation"
import SEO from "../components/seo"
import Container from "../components/container/container"
import styles from "../components/case-studies/case-studies.module.scss"

const CaseStudiesPage = ({ data }) => {
  const [showNav, setShowNav] = useState(false)
  const bgClass = "caseStudies"
  const studies = data.allStrapiCaseStudies.edges
  // const studies = data.allStrapiCaseStudies.edges.filter(
  //   (item, key) => key !== 0
  // )
  // const caseStudies = split(studies)

  // function split(studies) {
  //   const odd = studies.filter((number, key) => key % 2 === 1).reverse()
  //   const even = studies.filter((number, key) => key % 2 === 0).reverse()
  //   return { odd, even }
  // }

  return (
    <div>
      <SEO title="Case Studies" />
      <Banner
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}
        title="Case Studies"
        summary="Intelligent branding solutions"
        image={data.allStrapiCaseStudies.edges[0].node.FeaturedImage.publicURL}
        bgClass={bgClass}
        openNav={() => setShowNav(true)}></Banner>
      {showNav ? (
        <Nav
          logo={data.strapiGlobal.logo.publicURL}
          close={() => setShowNav(false)}></Nav>
      ) : null}

      <Container>
        <div className={styles.caseStudies}>
          {studies.map((caseStudy, key) => (
            <div key={key} className={styles.caseStudies__item}>
              <Link to={`/${caseStudy.node.Slug}`}>
                <img src={caseStudy.node.FeaturedImage.publicURL} />
                {/* <p>{caseStudy.node.Title}</p> */}
              </Link>
            </div>
          ))}
        </div>
      </Container>

      <Testimonial bgClass={bgClass}></Testimonial>
      <Footer
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}></Footer>
    </div>
  )
}

export default CaseStudiesPage

export const pageQuery = graphql`
  query CaseStudiesQuery {
    strapiGlobal {
      email
      logo {
        publicURL
      }
    }
    allStrapiCaseStudies {
      edges {
        node {
          id
          Title
          Slug
          FeaturedImage {
            publicURL
          }
        }
      }
    }
  }
`
